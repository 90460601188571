import { useState } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import InputMask from "react-input-mask";
import { format } from "date-fns";
import { useLocation, useNavigate } from 'react-router-dom';
import { getPublicIP } from '../services/ipService';
import { login } from '../services/authService';


const Inscricao = () => {
    const navigate = useNavigate();
    const [step, setStep] = useState(1); // 1 = Cadastro, 2 = Pagamento
    const [attendees, setAttendees] = useState([]);
    const [subtotal, setSubtotal] = useState(300);
    const [responsavel, setResponsavel] = useState(null);
    const [metodoPagamento, setMetodoPagamento] = useState("pix"); // PIX como padrão
    const [installments, setInstallments] = useState("1");

    // Parâmetros
    const limitePessoas = 4; // Limite de pessoas por inscrição
    const valorPix = 300; // Valor por pessoa no PIX
    const valorCartaoVista = 300; // Valor por pessoa no Cartão à vista
    const valorCartaoParcelado = 330; // Valor por pessoa no Cartão parcelado

    const [modalVisible, setModalVisible] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalSuccess, setModalSuccess] = useState(false);
    const [modalVisibleError, setModalVisibleError] = useState(false);
    const [modalError, setModalError] = useState(false);

    const closeModal = () => {
        setModalVisible(false);
        window.location.reload();
    }

    const closeModalError = () => {
      setModalVisibleError(false);
  }

    const calcularSubtotal = (parcelas) => {
        const parcelasSelecionadas = parcelas || installments;
        if (metodoPagamento === "cartao" && parcelasSelecionadas === "1") {
            // Cartão à vista (300,00 por inscrição)
            return attendees.length * valorCartaoVista;
        } else if (metodoPagamento === "cartao" && parcelasSelecionadas > 1) {
            // Cartão parcelado (330,00 por inscrição)
            return attendees.length * valorCartaoParcelado;
        }
        // PIX (300,00 por inscrição)
        return attendees.length * valorPix;
    };

    const calcularValorParcelado = (parcelas) => {
        const subtotal = parcelas === 1
            ? attendees.length * valorCartaoVista // Valor à vista (300,00 por inscrição)
            : attendees.length * valorCartaoParcelado; // Valor parcelado (330,00 por inscrição)
        return (subtotal / parcelas).toFixed(2);
    };

    const handleInstallmentsChange = (e) => {
        const selectedInstallments = e.target.value;
        setInstallments(selectedInstallments); // Atualiza o número de parcelas
        const novoSubtotal = calcularSubtotal(selectedInstallments);
        setSubtotal(novoSubtotal); // Atualiza o subtotal
    };


    const handleAddPerson = (values, resetForm) => {
        if (attendees.length >= limitePessoas) {
            alert(`O limite de ${limitePessoas} pessoas por inscrição foi atingido.`);
            return;
        }
        setAttendees((prev) => [...prev, values]);
        resetForm();
    };

    const handleRemovePerson = (index) => {
        setAttendees((prev) => prev.filter((_, i) => i !== index));
        if (responsavel === index) setResponsavel(null);
    };

    const handleResponsavelChange = (index) => {
        setResponsavel(index);
    };

    const goToNextStep = () => {
        if (attendees.length === 0 || responsavel === null) {
            alert("Adicione pessoas e selecione o responsável antes de continuar.");
            return;
        }
        setStep(2);
    };

    const goToPreviousStep = () => {
        setStep(1);
    };

    function validarCPF(cpf) {
      // Remove caracteres não numéricos
      cpf = cpf.replace(/[^\d]+/g, "");
    
      // Verifica se tem 11 dígitos ou se todos os dígitos são iguais
      if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
        return false;
      }
    
      // Valida os dígitos verificadores
      let soma = 0;
      let resto;
    
      // Validação do primeiro dígito
      for (let i = 1; i <= 9; i++) {
        soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
      }
      resto = (soma * 10) % 11;
      if (resto === 10 || resto === 11) resto = 0;
      if (resto !== parseInt(cpf.substring(9, 10))) {
        return false;
      }
    
      // Validação do segundo dígito
      soma = 0;
      for (let i = 1; i <= 10; i++) {
        soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
      }
      resto = (soma * 10) % 11;
      if (resto === 10 || resto === 11) resto = 0;
      if (resto !== parseInt(cpf.substring(10, 11))) {
        return false;
      }
    
      return true;
    }
    

    const confirmarPagamento = async (values) => {
        try {
            let token = localStorage.getItem('token');
            if (!token) {
              token = await login(); // Ajuste com as credenciais reais
            }

            const userIp = await getPublicIP(); // Obtém o IP público do usuário

            const response = await fetch('https://apiumadmec.elshadaysystems.com.br/api/inscricoes/retiro', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    attendees: attendees,
                    responsable: responsavel,
                    metodoPagamento: metodoPagamento,
                    installments: installments, // Valor atualizado dinamicamente
                    subtotal: calcularSubtotal(installments),
                    email: values.email,
                    cpf: values.cpf,
                    ip: userIp,
                    pagamento:
                    {
                        metodo: metodoPagamento === 'cartao' ? 'credit' : 'pix',
                        parcelas: installments,
                        titularCartao: values.cardHolderName,
                        numeroCartao: values.cardNumber,
                        bandeiraCartao: values.cardBrand,
                        validadeCartao: values.expiryDate,
                        cvvCartao: values.cvv
                    }
                })
            });

            const data = await response.json();
            if (response.ok) {
                if (metodoPagamento === 'pix') {
                    navigate('/pix-payment', { state: { cpf:values.cpf, cielo: data.cielo } });
                } else {
                    setModalSuccess(true);
                    setModalMessage(data.message || "Inscrição realizada com sucesso!");
                    setModalVisible(true); // Exibe o modal no final da operação
                }
            } else {
                setModalError(false);
                setModalMessage(data.message || "Erro ao realizar inscrição.");
                setModalVisibleError(true); // Exibe o modal no final da operação
            }
        } catch (error) {
            console.error("Erro ao tentar criar inscrição:", error);
            setModalError(false);
            setModalMessage("Erro ao realizar inscrição. Tente novamente.");
            setModalError(true); // Exibe o modal no final da operação
        } 
    };


    return (
        <div className="max-w-4xl mx-auto p-6 bg-gray-800 text-white rounded shadow-md">
            <h1 className="text-2xl font-bold mb-4">
                Inscrição para o Retiro de Páscoa 2025
            </h1>

            {/* Modal */}
            {modalVisible && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-6 rounded shadow-md text-center">
                        <h2
                            className={`text-lg font-bold mb-4 ${modalSuccess ? "text-green-600" : "text-red-600"}`}
                        >
                            {modalSuccess ? "Sucesso" : "Erro"}
                        </h2>
                        <p className="mb-4 text-black" >{modalMessage}</p>
                        <button
                            onClick={closeModal}
                            className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
                        >
                            Fechar
                        </button>
                    </div>
                </div>
            )}

            {modalVisibleError && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-6 rounded shadow-md text-center">
                        <h2
                            className={`text-lg font-bold mb-4 ${modalSuccess ? "text-green-600" : "text-red-600"}`}
                        >
                            {modalSuccess ? "Sucesso" : "Erro"}
                        </h2>
                        <p className="mb-4 text-black" >{modalMessage}</p>
                        <button
                            onClick={closeModalError}
                            className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
                        >
                            Fechar
                        </button>
                    </div>
                </div>
            )}            

            {/* Controle de Etapas */}
            <div className="mb-6 flex justify-between">
                <div
                    className={`px-4 py-2 rounded ${step === 1 ? "bg-blue-500 text-white" : "bg-gray-700"
                        }`}
                >
                    1. Cadastro
                </div>
                <div
                    className={`px-4 py-2 rounded ${step === 2 ? "bg-blue-500 text-white" : "bg-gray-700"
                        }`}
                >
                    2. Pagamento
                </div>
            </div>

            {/* Passo 1: Cadastro */}
            {step === 1 && (
                <>
                    <Formik
                        initialValues={{
                            name: "",
                            phone: "",
                            church: "",
                            birthDate: "",
                            termsAccepted: false,
                        }}
                        validationSchema={Yup.object().shape({
                            name: Yup.string().required("Nome é obrigatório"),
                            phone: Yup.string().required("Telefone é obrigatório"),
                            church: Yup.string().required("Igreja é obrigatória"),
                            birthDate: Yup.date()
                                .required("Data de Nascimento é obrigatória")
                                .max(new Date(), "A data de nascimento não pode ser futura"),
                            termsAccepted: Yup.boolean().oneOf(
                                [true],
                                "Você deve aceitar as regras."
                            ),
                        })}
                        onSubmit={(values, { resetForm }) => {
                            handleAddPerson(values, resetForm);
                        }}
                    >
                    {({ errors, touched }) => (
                      <Form className="bg-gray-800 p-4 rounded shadow-md">
                        <div className="grid grid-cols-1 gap-4">
                          {/* Campo Nome Completo */}
                          <div>
                            <label className="block text-sm font-medium mb-1">
                              Nome Completo
                            </label>
                            <Field
                              name="name"
                              placeholder="Nome Completo"
                              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring text-black"
                            />
                            {errors.name && touched.name && (
                              <div className="text-red-500 text-sm mt-1">{errors.name}</div>
                            )}
                          </div>

                          {/* Campo Telefone */}
                          <div>
                            <label className="block text-sm font-medium mb-1">
                              Telefone Celular
                            </label>
                            <Field name="phone">
                              {({ field }) => (
                                <InputMask
                                  {...field}
                                  mask="(99) 99999-9999"
                                  placeholder="Telefone Celular"
                                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring text-black"
                                />
                              )}
                            </Field>
                            {errors.phone && touched.phone && (
                              <div className="text-red-500 text-sm mt-1">{errors.phone}</div>
                            )}
                          </div>

                          {/* Campo Igreja */}
                          <div>
                            <label className="block text-sm font-medium mb-1">Igreja</label>
                            <Field
                              name="church"
                              placeholder="Igreja"
                              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring text-black"
                            />
                            {errors.church && touched.church && (
                              <div className="text-red-500 text-sm mt-1">{errors.church}</div>
                            )}
                          </div>

                          {/* Campo Data de Nascimento */}
                          <div>
                            <label className="block text-sm font-medium mb-1">
                              Data de Nascimento
                            </label>
                            <Field name="birthDate">
                              {({ field }) => (
                                <input
                                  {...field}
                                  type="date"
                                  max={new Date().toISOString().split("T")[0]}
                                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring text-black"
                                />
                              )}
                            </Field>
                            {errors.birthDate && touched.birthDate && (
                              <div className="text-red-500 text-sm mt-1">
                                {errors.birthDate}
                              </div>
                            )}
                          </div>
                        </div>

                        {/* Regras e Checkbox */}
                        <div className="mt-6">
                          <a
                            href="/regras.pdf"
                            target="_blank"
                            className="text-blue-500 underline"
                          >
                            Leia as Regras do Retiro
                          </a>
                          <div className="mt-3">
                            <label className="flex items-center space-x-2">
                              <Field type="checkbox" name="termsAccepted" />
                              <span className="text-sm">
                                Confirmo que li as regras
                              </span>
                            </label>
                            {errors.termsAccepted && touched.termsAccepted && (
                              <div className="text-red-500 text-sm mt-1">
                                {errors.termsAccepted}
                              </div>
                            )}
                          </div>
                        </div>

                        {/* Botão de Submissão */}
                        <div className="mt-6 flex justify-center">
                          <button
                            type="submit"
                            className="bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600"
                          >
                            Adicionar Pessoa
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>

                  {/* Tabela de Pessoas */}
                  <div className="mt-6">
                    <h2 className="text-lg font-bold mb-4">Lista de Pessoas</h2>
                    <div className="overflow-x-auto">
                      <table className="min-w-full bg-gray-800 border rounded shadow">
                        <thead>
                          <tr className="bg-gray-700 text-left">
                          <th className="p-2 whitespace-nowrap">Responsável</th>
                            <th className="p-2 whitespace-nowrap">Nome</th>
                            <th className="p-2 whitespace-nowrap">Telefone</th>
                            <th className="p-2 whitespace-nowrap">Igreja</th>
                            <th className="p-2 whitespace-nowrap">Nascimento</th>
                            <th className="p-2 whitespace-nowrap">Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {attendees.map((person, index) => (
                            <tr key={index} className="border-t border-gray-600">
                              <td className="p-2 whitespace-nowrap">
                                <input
                                  type="radio"
                                  name="responsavel"
                                  checked={responsavel === index}
                                  onChange={() => handleResponsavelChange(index)}
                                />
                              </td>

                              <td className="p-2 whitespace-nowrap">{person.name}</td>
                              <td className="p-2 whitespace-nowrap">{person.phone}</td>
                              <td className="p-2 whitespace-nowrap">{person.church}</td>
                              <td className="p-2 whitespace-nowrap">
                                {format(new Date(person.birthDate + "T00:00:00"), "dd/MM/yyyy")}
                              </td>
                              <td className="p-2 whitespace-nowrap">
                                <button
                                  onClick={() => handleRemovePerson(index)}
                                  className="text-red-500 hover:underline"
                                >
                                  Remover
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                    <button
                        onClick={goToNextStep}
                        className="mt-6 bg-green-500 text-white p-2 rounded hover:bg-green-600"
                    >
                        Prosseguir para Pagamento
                    </button>
                </>
            )}

            {/* Passo 2: Pagamento */}
            {step === 2 && (
                <Formik
                    initialValues={{
                        email: "",
                        cpf: "",
                        installments: "1",
                        cardHolderName: "",
                        cardNumber: "",
                        cardBrand: "",
                        expiryDate: "",
                        cvv: "",
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string()
                            .email("Digite um e-mail válido") // Validação de formato
                            .required("E-mail é obrigatório"),
                        cpf: Yup.string().required("O CPF do responsável é obrigatório")
                        .test("validar-cpf", "CPF inválido", (value) => validarCPF(value || "")),
                        installments: Yup.string().required("Selecione o número de parcelas"),
                        ...(metodoPagamento === "cartao" && {
                          cardHolderName: Yup.string().required("Nome do titular é obrigatório"),
                          cardNumber: Yup.string().required("Número do cartão é obrigatório"),
                          cardBrand: Yup.string().required("Selecione a bandeira"),
                          expiryDate: Yup.string().required("Validade do cartão é obrigatória"),
                          cvv: Yup.string().required("CVV é obrigatório"),
                      }),
                    })}
                    onSubmit={(values) => {
                        // Exibe a mensagem de confirmação
                        const userConfirmed = window.confirm(
                          "Tem certeza de que deseja confirmar o pagamento?"
                        );

                        if (userConfirmed) {
                          confirmarPagamento(values); // Chama a função para processar o pagamento
                        }
                    }}
                >
                    {({ errors, touched }) => (
                        <Form>
                            <div className="mt-4">
                                <label className="block text-sm font-medium">CPF</label>
                                <Field
                                    name="cpf"
                                    as={InputMask}
                                    mask="999.999.999-99"
                                    placeholder="Digite seu CPF"
                                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring text-black"
                                />
                                {errors.cpf && touched.cpf && (
                                    <div className="text-red-500 text-sm">{errors.cpf}</div>
                                )}
                            </div>

                            <div className="mt-4">
                                <label className="block text-sm font-medium">E-mail</label>
                                <Field
                                    name="email"
                                    type="email"
                                    placeholder="Digite seu e-mail"
                                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring text-black"
                                />
                                {errors.email && touched.email && (
                                    <div className="text-red-500 text-sm">{errors.email}</div>
                                )}
                            </div>

                            <div className="mt-4">
                                <label className="block text-sm font-medium">
                                    Método de Pagamento
                                </label>
                                <select
                                    value={metodoPagamento}
                                    onChange={(e) => setMetodoPagamento(e.target.value)}
                                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring text-black"
                                >
                                    <option value="pix">PIX</option>
                                    <option value="cartao">Cartão de Crédito</option>
                                </select>
                            </div>

                            {metodoPagamento === "cartao" && (
                                <>
                                    <div className="mt-4">
                                        <label className="block text-sm font-medium">Parcelas</label>
                                        <Field
                                            as="select"
                                            name="installments"
                                            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring text-black"
                                            onChange={handleInstallmentsChange}
                                            value={installments}
                                        >
                                            {[...Array(5).keys()].map((i) => {
                                                const parcela = i + 1;
                                                const valorParcela = calcularValorParcelado(parcela); // Calcular dinamicamente
                                                return (
                                                    <option key={parcela} value={parcela}>
                                                        {parcela}x - R$ {valorParcela}
                                                    </option>
                                                );
                                            })}
                                        </Field>
                                    </div>

                                    <div className="mt-4">
                                        <label className="block text-sm font-medium">
                                            Nome do Titular
                                        </label>
                                        <Field
                                            name="cardHolderName"
                                            type="text"
                                            placeholder="Nome do titular do cartão"
                                            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring text-black"
                                        />
                                        {errors.cardHolderName && touched.cardHolderName && (
                                            <div className="text-red-500 text-sm">
                                                {errors.cardHolderName}
                                            </div>
                                        )}
                                    </div>
                                    <div className="mt-4">
                                        <label className="block text-sm font-medium">
                                            Número do Cartão
                                        </label>
                                        <Field
                                            name="cardNumber"
                                            type="text"
                                            placeholder="Número do cartão"
                                            maxLength="19"
                                            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring text-black"
                                        />
                                        {errors.cardNumber && touched.cardNumber && (
                                            <div className="text-red-500 text-sm">
                                                {errors.cardNumber}
                                            </div>
                                        )}
                                    </div>
                                    <div className="mt-4">
                                        <label className="block text-sm font-medium">Bandeira do Cartão</label>
                                        <Field
                                            as="select"
                                            name="cardBrand"
                                            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring text-black"
                                        >
                                            <option value="">Selecione a Bandeira</option>
                                            <option value="Amex">Amex</option>
                                            <option value="Aura">Aura</option>
                                            <option value="Diners">Diners</option>
                                            <option value="Discover">Discover</option>
                                            <option value="Elo">Elo</option>
                                            <option value="Hiper">Hiper</option>
                                            <option value="Hipercard">Hipercard</option>
                                            <option value="Master">Master</option>
                                            <option value="Visa">Visa</option>
                                        </Field>
                                    </div>
                                    <div className="mt-4">
                                        <label className="block text-sm font-medium">
                                            Validade do Cartão
                                        </label>
                                        <Field
                                            name="expiryDate"
                                            as={InputMask}
                                            mask="99/9999"
                                            placeholder="MM/AAAA"
                                            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring text-black"
                                        />
                                        {errors.expiryDate && touched.expiryDate && (
                                            <div className="text-red-500 text-sm">{errors.expiryDate}</div>
                                        )}
                                    </div>
                                    <div className="mt-4">
                                        <label className="block text-sm font-medium">CVV</label>
                                        <Field
                                            name="cvv"
                                            type="text"
                                            placeholder="CVV"
                                            maxLength="4"
                                            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring text-black"
                                        />
                                        {errors.cvv && touched.cvv && (
                                            <div className="text-red-500 text-sm">{errors.cvv}</div>
                                        )}
                                    </div>
                                </>
                            )}

                            {metodoPagamento === "pix" && (
                                <div className="mt-4">
                                    <h3 className="text-lg font-semibold">Pagamento via PIX</h3>
                                    <p>Após confirmar, você receberá os dados para pagamento.</p>
                                </div>
                            )}

                          <div className="flex flex-col md:flex-row justify-between items-center gap-4 mt-6">
                              <button
                                  onClick={goToPreviousStep}
                                  className="bg-gray-600 text-white w-full md:w-auto px-4 py-2 rounded hover:bg-gray-700"
                              >
                                  Voltar ao Cadastro
                              </button>
                              <div className="text-lg font-bold w-full md:w-auto text-center md:text-left">
                                  Total: R$ {calcularSubtotal().toFixed(2)}
                              </div>
                              <button
                                  type="submit"
                                  className="bg-green-500 text-white w-full md:w-auto px-4 py-2 rounded hover:bg-green-600"
                              >
                                  Confirmar Pagamento
                              </button>
                          </div>
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
};

export default Inscricao;
