import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { verificarPagamentoAPI } from '../services/registrationService';

function PixPayment() {
  const { state } = useLocation();
  const cpf = state?.cpf;
  const cielo = state?.cielo;
  const navigate = useNavigate();
  
  const [pagamentoConfirmado, setPagamentoConfirmado] = useState(false);
  const [loading, setLoading] = useState(true);
  const [copySuccess, setCopySuccess] = useState('');

  useEffect(() => {
    const verificarPagamento = async () => {
      const data = await verificarPagamentoAPI(cielo.PaymentId);

      if (data.confirmado) {
        setPagamentoConfirmado(true);
        setLoading(false);
      } else {
        setTimeout(verificarPagamento, 5000); // Tenta novamente em 5 segundos
      }
    };

    // Inicia a verificação de pagamento assim que o componente é montado
    verificarPagamento();
  }, [cielo.PaymentId]);

  const handleGoHome = () => {
    navigate('/'); // Redireciona para a página inicial
  };

  const handleCopyPix = async () => {
    try {
      await navigator.clipboard.writeText(cielo.QrCodeString);
      setCopySuccess('Chave PIX copiada com sucesso!');
    } catch (error) {
      setCopySuccess('Falha ao copiar a chave PIX');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-4 bg-white shadow-md rounded-lg text-center">
        <h2 className="text-2xl font-bold">Pagamento com PIX</h2>
        <p className="text-lg">CPF: {cpf}</p>
        {pagamentoConfirmado ? (
          <>
            <p className="text-green-600 font-bold text-lg">Pagamento confirmado com sucesso!</p>
            <button
              onClick={handleGoHome}
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Voltar para a página inicial
            </button>
          </>
        ) : (
          <>
            <p className="text-lg">Escaneie o QR Code abaixo para concluir o pagamento:</p>
            <img src={`data:image/png;base64,${cielo.QrCodeBase64Image}`} alt="qrcode para pagamento" className="mx-auto w-64 h-64" />
            
            <button
              onClick={handleCopyPix}
              className="mt-4 px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
            >
              Copiar chave PIX
            </button>
            {copySuccess && <p className="text-green-600 mt-2">{copySuccess}</p>}
            
            {loading && <p className="text-gray-500 mt-4">Aguardando confirmação de pagamento...</p>}
          </>
        )}
      </div>
    </div>
  );
}

export default PixPayment;
