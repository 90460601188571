const email = process.env.REACT_APP_API_EMAIL;
const password = process.env.REACT_APP_API_PASSWORD;

export async function login() {
  const response = await fetch('https://apiumadmec.elshadaysystems.com.br/api/auth/login', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password })
  });

  if (response.ok) {
    const data = await response.json();
    localStorage.setItem('token', data.token);
    return data.token;
  } else {
    throw new Error('Falha na autenticação');
  }
}

// Função para obter o token do localStorage
export function getToken() {
  return localStorage.getItem('token');
}
