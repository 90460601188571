import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { createRegistration } from '../services/registrationService';

function Register() {
  const { state } = useLocation();
  const { data } = state;
  const navigate = useNavigate();
  const [cpf, setCpf] = useState(state?.cpf || '');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [installments, setInstallments] = useState('');
  const [cardHolderName, setCardHolderName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cardBrand, setCardBrand] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cvv, setCvv] = useState('');
  const [registrationFee, setRegistrationFee] = useState(90.00);
  const [installmentOptions, setInstallmentOptions] = useState([]);
  const interestRate = 0.0242;

  // Estado para o modal
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const calculateInstallments = () => {
      const options = [];
        
      for (let i = 1; i <= 5; i++) {
        let installmentValue;
  
        if (i === 1) {
          // Sem juros para a primeira parcela
          installmentValue = registrationFee;
        } else {
          // Aplica o juros sobre o valor total para as demais parcelas
          const totalWithInterest = registrationFee * (1 + interestRate); 
          installmentValue = totalWithInterest / i;
        }
  
        options.push({
          label: `${i}x R$${installmentValue.toFixed(2)}`,
          value: i,
        });
      }
      setInstallmentOptions(options);
    };
  
    calculateInstallments();
  }, [registrationFee]);

  const handleCpfChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setCpf(value);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handlePhoneChange = (e) => {
    let value = e.target.value.replace(/\D/g, '');
    if (value.length <= 2) {
      value = `(${value}`;
    } else if (value.length <= 7) {
      value = `(${value.slice(0, 2)}) ${value.slice(2)}`;
    } else if (value.length <= 11) {
      value = `(${value.slice(0, 2)}) ${value.slice(2, 7)}-${value.slice(7)}`;
    } else {
      value = `(${value.slice(0, 2)}) ${value.slice(2, 7)}-${value.slice(7, 11)}`;
    }
    setPhone(value);
  };

  const handleCardNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, '').replace(/(\d{4})(?=\d)/g, '$1 ');
    setCardNumber(value);
  };

  const handleExpiryDateChange = (e) => {
    const value = e.target.value.replace(/\D/g, '').replace(/(\d{2})(?=\d)/, '$1/');
    setExpiryDate(value);
  };

  const handleCvvChange = (e) => {
    const value = e.target.value.replace(/\D/g, '').slice(0, 4);
    setCvv(value);
  };

  const handleRegister = async () => {
    const plainPhone = phone.replace(/\D/g, ''); // Remove a máscara para validação

    if (!plainPhone || plainPhone.length !== 11 || !validateCPF(cpf) || !email || !validateEmail(email)) {
      setModalMessage("Preencha todos os campos corretamente");
      setShowModal(true);
      return;
    }

    if (!paymentMethod) {
      setModalMessage("Selecione um método de pagamento");
      setShowModal(true);
      return;
    }

    if (paymentMethod === 'credit') {
      if (!installments || !cardHolderName || !cardNumber || !cardBrand || !expiryDate || !cvv) {
        setModalMessage("Preencha todos os dados do cartão");
        setShowModal(true);
        return;
      }
    }

    // Exibe o modal de confirmação antes do envio
    setShowConfirmModal(true);
  };


  const validateCPF = (cpf) => {
    return cpf.length === 11 && /^\d{11}$/.test(cpf);
  };

  const confirmRegistration = async () => {
    setShowConfirmModal(false); // Fecha o modal de confirmação
    setIsLoading(true); // Ativa o loading antes da requisição

    if (paymentMethod === 'pix') {
      const result = await createRegistration(cpf, phone, email, data?.id, paymentMethod, installments, cardHolderName, cardNumber, cardBrand, expiryDate, cvv);
      if(result.success){
        navigate('/pix-payment', { state: { cpf, cielo: result.cielo } });
      }else{
        setModalMessage(result.message);
        setSuccess(result.success);
        setShowModal(true);        
      }
      
    } else {
      const result = await createRegistration(cpf, phone, email, data?.id, paymentMethod, installments, cardHolderName, cardNumber, cardBrand, expiryDate, cvv);
      setModalMessage(result.message);
      setSuccess(result.success);
      setShowModal(true);
    }

    setIsLoading(false); // Desativa o loading após a requisição
  };  

  const closeModal = () => {
    setShowModal(false);
    // Voltar para a tela inicial após o usuário fechar o modal de sucesso
    if (success) {
      navigate('/');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-4 bg-white shadow-md rounded-lg">
        <h2 className="text-2xl font-bold text-center">Registrar Inscrição | EBOM 2024</h2>
        <p className="text-lg font-semibold text-center">Valor da inscrição: R${registrationFee.toFixed(2)}</p>
        {data && (  
          <>
        <p>Inscrição: {data.codigoInscricao}</p>
        <p>Nome: {data.membro.nome}</p>
        <p>Igreja: {data.igreja.nome}</p>
        </>
        )}

        <input
          type="text"
          placeholder="CPF"
          value={cpf}
          onChange={handleCpfChange}
          className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring disabled"
          disabled={true}
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring"
        />
        <input
          type="text"
          placeholder="Celular"
          value={phone}
          onChange={handlePhoneChange}
          className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring"
        />
        <select
          value={paymentMethod}
          onChange={(e) => setPaymentMethod(e.target.value)}
          className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring"
        >
          <option value="">Como deseja pagar?</option>
          <option value="credit">Crédito</option>
          <option value="pix">PIX</option>
        </select>

        {paymentMethod === 'credit' && (
          <div className="space-y-4">
            <select
              value={installments}
              onChange={(e) => setInstallments(e.target.value)}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring"
            >
              <option value="">Parcelas</option>
              {installmentOptions.map((option) => (
                <option key={option.value} value={option.value}>{option.label}</option>
              ))}
            </select>
            <input
              type="text"
              placeholder="Nome do titular do cartão"
              value={cardHolderName}
              onChange={(e) => setCardHolderName(e.target.value)}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring"
              required={paymentMethod === 'credit'}
            />
            <input
              type="text"
              placeholder="Número do cartão"
              value={cardNumber}
              onChange={handleCardNumberChange}
              maxLength="19"
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring"
              required={paymentMethod === 'credit'}
            />
            <select
              value={cardBrand}
              onChange={(e) => setCardBrand(e.target.value)}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring"
              required={paymentMethod === 'credit'}
            >
              <option value="">Bandeira</option>
              <option value="Amex">Amex</option>
              <option value="Aura">Aura</option>
              <option value="Diners">Diners</option>
              <option value="Discover">Discover</option>
              <option value="Elo">Elo</option>
              <option value="Hiper">Hiper</option>
              <option value="Hipercard">Hipercard</option>
              <option value="Master">Master</option>
              <option value="Visa">Visa</option>
            </select>
            <input
              type="text"
              placeholder="Validade (MM/AAAA)"
              value={expiryDate}
              onChange={handleExpiryDateChange}
              maxLength="7"
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring"
              required={paymentMethod === 'credit'}
            />
            <input
              type="text"
              placeholder="Código de Segurança (CVV)"
              value={cvv}
              onChange={handleCvvChange}
              maxLength="4"
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring"
              required={paymentMethod === 'credit'}
            />
          </div>
        )}

        <button
          onClick={handleRegister}
          className={`w-full px-4 py-2 text-white rounded-md ${isLoading ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'}`}
          disabled={isLoading}
        >
          {isLoading ? 'Carregando...' : (paymentMethod === 'pix' ? 'Avançar' : 'Registrar')}
        </button>
      </div>

      {/* Modal de confirmação */}
      {showConfirmModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg text-center">
            <p>Tem certeza de que deseja prosseguir com a inscrição?</p>
            <div className="mt-4 flex justify-center space-x-4">
              <button
                onClick={confirmRegistration}
                className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
              >
                Confirmar
              </button>
              <button
                onClick={() => setShowConfirmModal(false)}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}      

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg text-center">
            <p>{modalMessage}</p>
            <button
              onClick={closeModal}
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mx-auto"
            >
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Register;
