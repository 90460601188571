import React from 'react';
import { useLocation } from 'react-router-dom';

function Payment() {
  const { state } = useLocation();
  const { cpf, data } = state;

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-4 bg-white shadow-md rounded-lg">
        <h2 className="text-2xl font-bold text-center">Pagamento da Inscrição | EBOM 2024</h2>
        <p>CPF: {cpf}</p>
        <p>Inscrição: {data.codigoInscricao}</p>
        <p>Nome: {data.membro.nome}</p>
        <p>Igreja: {data.igreja.nome}</p>
        {/* Adicionar aqui as opções de pagamento */}
        <button className="w-full px-4 py-2 mt-4 text-white bg-blue-500 rounded-md hover:bg-blue-600">
          Pagar com PIX
        </button>
        <button className="w-full px-4 py-2 mt-4 text-white bg-blue-500 rounded-md hover:bg-blue-600">
          Pagar com Cartão de Crédito
        </button>
      </div>
    </div>
  );
}

export default Payment;
